import * as React from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';
import {MenuItemLink, getResources} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import UserIcon from '@material-ui/icons/AccountCircle';
import UrlIcon from '@material-ui/icons/Http';
import DomainIcon from '@material-ui/icons/Domain';
import SecurityIcon from '@material-ui/icons/Security';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PowerIcon from '@material-ui/icons/Power';
import WebIcon from '@material-ui/icons/Web';
import LanguageIcon from '@material-ui/icons/Language';
import FolderIcon from '@material-ui/icons/Folder';


/* eslint-disable react/prop-types */
const Menu = ({onMenuClick, logout}) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      {resources.map((resource) => {
        if (
          (resource.name === 'users' ||
            resource.name === 'logs' ||
            resource.name === 'sectors') &&
          (
            !window.localStorage.getItem('roles') ||
              window.localStorage.getItem('roles').indexOf('ROLE_ADMIN') <= -1
          )
        ) {
          return '';
        }

        const icons = {
          'users': <UserIcon />,
          'urls': <UrlIcon />,
          'sectors': <DomainIcon />,
          'technologies': <PowerIcon />,
          'url_types': <WebIcon />,
          'websites': <LanguageIcon />,
          'website_sources': <FolderIcon />,
          'logs': <SecurityIcon />,
        };
        return <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
                        resource.name
          }
          leftIcon={
            resource.name in icons ? icons[resource.name] : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />;
      })}
      {isXSmall}
      {logout}
    </div>
  );
};

export default Menu;
