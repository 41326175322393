const checkTokenState = () => {
  const request = new Request(
      process.env.REACT_APP_WEBPERF_API_URL + '/me',
      {
        method: 'GET',
        headers: new Headers(
            {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        ),
      },
  );

  fetch(request).then((response) => {
    if (response.status < 200 || response.status >= 300) {
      localStorage.removeItem('token');
      localStorage.removeItem('roles');
    }
  });
};

checkTokenState(); // Check at startup to reset old token

const authProvider = {
  login: ({username, password}) => {
    const request = new Request(
        process.env.REACT_APP_WEBPERF_API_URL + '/authentication_token',
        {
          method: 'POST',
          body: JSON.stringify({username, password}),
          headers: new Headers({'Content-Type': 'application/json'}),
        },
    );
    return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(({token}) => {
          localStorage.setItem('token', token);
          const request = new Request(
              process.env.REACT_APP_WEBPERF_API_URL + '/me',
              {
                method: 'GET',
                headers: new Headers(
                    {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                ),
              },
          );
          return fetch(request)
              .then((response) => {
                return response.json();
              })
              .then(({roles}) => {
                localStorage.setItem('roles', roles);
              });
        });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('roles');
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem('token')) {
      return Promise.resolve();
    } else {
      localStorage.removeItem('roles');
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({redirectTo: '/no-access'});
    }
  },
  getPermissions: (params) => {
    return Promise.resolve();
  },
};

export default authProvider;
