import {createMuiTheme} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const SynoliaTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#bb2280',
    },
    secondary: {
      main: '#bb2280',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiMenuItem: {
      root: {
        lineHeight: 2.5,
      },
    },
  },
});

export default SynoliaTheme;
