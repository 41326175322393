import React from 'react';
import {
  HydraAdmin,
  ResourceGuesser,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  ListGuesser,
  InputGuesser,
} from '@api-platform/admin';
// eslint-disable-next-line max-len
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {Redirect, Route} from 'react-router-dom';
import SynoliaLayout from './layout/SynoliaLayout';
import SynoliaTheme from './layout/SynoliaTheme';
import LoginPage from './layout/LoginPage';
import { makeStyles } from '@material-ui/core/styles';
import {cloneElement} from 'react';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  BooleanInput,
  TextField,
  ReferenceField,
  AutocompleteInput,
  SelectArrayInput} from 'react-admin';
import authProvider from './security/authProvider';
import PhoneIcon from '@material-ui/icons/Phone';
import DesktopIcon from '@material-ui/icons/DesktopMac';
import ImportButton from './url/ImportButton';
import ExportButton from './url/ExportButton';


const style = makeStyles({
  alert: {
    color: 'red',
  },
  warning: {
    color: 'orange',
  },
  success: {
    color: 'green',
  },
  hide: {
    display: 'none',
  },
});

const entrypoint = process.env.REACT_APP_WEBPERF_API_URL;
const fetchHeaders = {
  Authorization: `Bearer ${window.localStorage.getItem('token')}`,
};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders),
});

// eslint-disable-next-line max-len
const apiDocumentationParser = (entrypoint) => parseHydraDocumentation(entrypoint, {headers: new Headers(fetchHeaders)})
    .then(
        ({api}) => ({api}),
        (result) => {
          switch (result.status) {
            case 401:
              return Promise.resolve({
                api: result.api,
                customRoutes: [
                  // eslint-disable-next-line react/jsx-key
                  <Route path="/" render={() => {
                    return window.localStorage.getItem('token') ?
                      window.location.reload() :
                      <Redirect to="/login" />;
                  }} />,
                ],
              });

            default:
              return Promise.reject(result);
          }
        },
    );

const dataProvider =
    baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const strategyChoice = [
  {id: 'desktop', name: 'Desktop'},
  {id: 'mobile', name: 'Mobile'},
];

const UrlsCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="url" />
    <BooleanInput label="Enabled"
      source="enabled"
      defaultValue={true} />
    <ReferenceInput source="website" reference="websites">
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput source="urlType" reference="url_types">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectArrayInput
      source="strategy"
      choices={strategyChoice}
      optionText="name"
      optionValue="id" />
  </CreateGuesser>
);


const UrlsEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="url" />
    <BooleanInput label="Enabled"
      source="enabled"
      defaultValue={true} />
    <ReferenceInput source="website" reference="websites">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="urlType" reference="url_types">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectArrayInput
      source="strategy"
      choices={strategyChoice}
      optionText="name"
      optionValue="id" />
  </EditGuesser>
);

const WebsiteEdit = (props) =>
  <>
    {(() => {
      const classes = style();

      return (<EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="baseUrl" />
        <ReferenceInput source="websiteSource" reference="website_sources">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="technologies" reference="technologies"
          label="Technologies">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="sectors" reference="sectors"
          label="Sector">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </EditGuesser>)
    })()}
  </>


const WebsiteCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="baseUrl" />
    <ReferenceInput source="websiteSource" reference="website_sources">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="technologies" reference="technologies">
      <SelectArrayInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="sectors" reference="sectors">
      <SelectArrayInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
);

const WebsiteList = (props) => (
  <ListGuesser { ...props } hasShow={false}>
    <FieldGuesser source="name" />
    <FieldGuesser source="baseUrl" />
    <ReferenceField source="websiteSource" reference="website_sources">
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);


const NamedList = (props) => (
  <ListGuesser {...props} hasShow={false}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const UserList = (props) => (
  <ListGuesser {...props} hasShow={false}>
    <FieldGuesser source="username" />
    <FieldGuesser source="roles" />
  </ListGuesser>
);

const UserCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="username" />
    <InputGuesser source="password" />
    <SelectArrayInput source="roles" optionText="name" choices={[
      {id: 'ROLE_ADMIN', name: 'Administrateur'},
      {id: 'ROLE_USER', name: 'Utilisateur'}
    ]} />
  </CreateGuesser>
);

const UserEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="username" />
    <InputGuesser source="password" />
    <SelectArrayInput source="roles" optionText="name" choices={[
      {id: 'ROLE_ADMIN', name: 'Administrateur'},
      {id: 'ROLE_USER', name: 'Utilisateur'}
    ]} />
  </EditGuesser>
);
// eslint-disable-next-line react/prop-types
const StrategyField = ({record, source}) => (
  <>
    {(() => {
      const icons = [];
      if (!record) {
        return;
      }
      // eslint-disable-next-line react/prop-types
      if (record[source].indexOf('mobile') > -1) {
        icons.push(<PhoneIcon key="phone" />);
      }
      // eslint-disable-next-line react/prop-types
      if (record[source].indexOf('desktop') > -1) {
        icons.push(<DesktopIcon key="desktop" />);
      }
      return <>{icons}</>;
    })()}
  </>
);




// eslint-disable-next-line react/prop-types
const HttpCodeField = ({record, source}) => (
  <>
    {(() => {
      if (!record) {
        return;
      }
      const classes = style();
      // eslint-disable-next-line react/prop-types
      if (record[source] === 200) {
        return <><span className={classes.success}>✅ {record[source]}</span></>;
      } else if ((record[source] >= 400 && record[source] < 500) ||
        (record[source] === 0)) {
        return <><span className={classes.alert}>🚨 {record[source]}</span></>;
      } else {
        return <>
          <span className={classes.warning}>⚠️ {record[source]}</span>
        </>;
      }
    })()}
  </>
);

const ListActions = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    className,
    // eslint-disable-next-line react/prop-types
    filters,
    // eslint-disable-next-line react/prop-types
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton />
      <ImportButton />
    </TopToolbar>
  );
};

const UrlList = (props) => (
  <ListGuesser {...props} actions={<ListActions />} hasShow={false}>
    <FieldGuesser label="Enabled" source="enabled" />
    <FieldGuesser source="url" />
    <ReferenceField source="website" reference="websites">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="urlType" reference="url_types">
      <TextField source="name" />
    </ReferenceField>
    <StrategyField source="strategy" />
    <HttpCodeField label="Last HTTP Code" source="lastHttpCode" />
    <FieldGuesser label="Last valid call" source="lastValidDate" />
  </ListGuesser>
);

// eslint-disable-next-line react/display-name
export default () => (
  <HydraAdmin
    loginPage={LoginPage}
    theme={SynoliaTheme}
    layout={SynoliaLayout}
    dataProvider={ dataProvider }
    authProvider={ authProvider }
    entrypoint={ entrypoint }>
    <ResourceGuesser
      name="urls"
      list={UrlList}
      create={UrlsCreate}
      edit={UrlsEdit} />
    <ResourceGuesser name="websites"
      create={WebsiteCreate}
      edit={ WebsiteEdit }
      list={ WebsiteList } />
    <ResourceGuesser name="users" list={UserList} create={UserCreate} edit={UserEdit} />
    <ResourceGuesser name="logs" />
    <ResourceGuesser name="sectors" list={NamedList} />
    <ResourceGuesser name="technologies" list={NamedList} />
    <ResourceGuesser name="url_types" list={NamedList} />
    <ResourceGuesser name="website_sources" list={NamedList} />
  </HydraAdmin>
);
