import React from 'react';
import {Login, LoginForm} from 'ra-ui-materialui';
import {withStyles} from '@material-ui/core/styles';

const styles = {
  login: {
    main: {
      // eslint-disable-next-line max-len
      backgroundImage: 'radial-gradient(circle at 50% 14em, #fff 0%, #bb2280 60%, #333 100%)',
    },
    card: {
      padding: '5px 0 15px 0',
    },
  },
  form: {
    button: {
      height: '3em',
    },
  },
};

const SynoliaLoginForm = withStyles(styles.form)(LoginForm);

const SynoliaLogin = (props) => (
  <Login loginForm={<SynoliaLoginForm />} {...props} />
);

export default withStyles(styles.login)(SynoliaLogin);
