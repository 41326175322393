/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, useRefresh} from 'react-admin';
import UploadIcon from '@material-ui/icons/Publish';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ImportButton = () => {
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const handleClickOpenHelp = () => {
    setHelpOpen(true);
  };

  const handleCloseHelp = () => {
    setHelpOpen(false);
  };

  const handleChange = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);

    const request = new Request(
        process.env.REACT_APP_WEBPERF_API_URL + '/urls/import',
        {
          method: 'POST',
          body: data,
          headers: new Headers(
              {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
          ),
        },
    );

    setLoading(true);
    fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return {
          'hydra:member': [{
            'lines': [],
            'message': 'Error from server',
          }],
        };
      }
      return response.json();
    }).then((json) => {
      const members = json['hydra:member'];
      const messages = [];
      members.forEach((item) => {
        if (item.lines.length > 0) {
          messages.push('😭 ' + item.lines.join(',') + ': ' + item.message);
        } else {
          messages.push('😭 ' + item.message);
        }
      });

      if (messages.length === 0) {
        messages.push('🤩 Everything is imported !');
      }

      setMessages(messages);
      setLoading(false);
      refresh();
    });
    event.target.value = '';
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessages([]);
  };


  const useStyles = makeStyles((theme) => ({

    inputFile: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

  const classes = useStyles();
  const refresh = useRefresh();

  return (
    <div>
      <input
        accept=".csv"
        id="contained-button-file"
        className={classes.inputFile}
        type="file"
        onChange={handleChange}
        disabled={loading}
      />
      <label htmlFor="contained-button-file">
        <Button
          label="Import"
          color="primary"
          component="span"
          disabled={loading}
        >
          <>
            <UploadIcon />
            {loading && <CircularProgress
              size={24}
              className={classes.buttonProgress}/>}
          </>
        </Button>

      </label>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClickOpenHelp}
      >
        <HelpIcon fontSize="small" />
      </IconButton>

      <Dialog
        open={helpOpen}
        onClose={handleCloseHelp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Format des imports'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ul>
              <li>Les import doivent être au format CSV</li>
              <li>Les champs possibles sont url, account, state,
                  accountType, pageType, strategy, technology,
                  shouldBePWA, sector, id, enabled</li>
              <li>Si un ID est précisé, l'url correspondant à cet
                  id est mis à jour, sinon elle est créée
                  (ATTENTION AUX DOUBLONS)</li>
              <li>Dans "sector", il faut mettre le nom d'un secteur
                  existant</li>
              <li>"state" peut avoir la valeur "LAST_SUCCESS" ou "LAST_ERROR"
                  (c'est le picto d'état affiché sur la liste)</li>
              <li>"strategy" peut avoir les valeurs "mobile", "desktop" ou
                  "mobile,desktop"</li>
              <li>"shouldBePWA" doit avoir la valeur "1" ou "0"</li>
              <li>"enabled" doit avoir la valeur "1" ou "0"</li>
              <li>"accountType" peut avoir la valeur "Client", "Concurrent"
                  ou "Autre"</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseHelp}
            color="primary"
            autoFocus
            label="Merci"
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={messages.length > 0}
        autoHideDuration={10000}
        onClose={handleClose}
        message={<React.Fragment>
          <List className={classes.root} subheader={<li />}>
            {messages.map((message) => {
              return (<ListItem key="{message}">{message}</ListItem>);
            })}
          </List>
        </React.Fragment>}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default ImportButton;
