/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, useRefresh} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';

const ExportButton = () => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = (event) => {
    const request = new Request(
        process.env.REACT_APP_WEBPERF_API_URL + '/urls/export',
        {
          method: 'GET',
          headers: new Headers(
              {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
          ),
        },
    );

    setLoading(true);
    fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return {
          'hydra:member': [{
            'lines': [],
            'message': 'Error from server',
          }],
        };
      }
      return response.json();
    }).then((response) => {
      
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response));
      element.setAttribute('download', 'urls.csv');
    
      element.style.display = 'none';
      document.body.appendChild(element);
    
      element.click();
      document.body.removeChild(element);

      setLoading(false);
      refresh();
    });
    event.target.value = '';
  };


  const useStyles = makeStyles((theme) => ({

    inputFile: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

  const classes = useStyles();
  const refresh = useRefresh();

  return (
    <div>
      <input
        accept=".csv"
        id="contained-button-export"
        className={classes.inputFile}
        type="button"
        onClick={handleClick}
        disabled={loading}
      />
      <label htmlFor="contained-button-export">
        <Button
          label="Export"
          color="primary"
          component="span"
          disabled={loading}
        >
          <>
            <DownloadIcon />
            {loading && <CircularProgress
              size={24}
              className={classes.buttonProgress}/>}
          </>
        </Button>

      </label>
    </div>
  );
};

export default ExportButton;
